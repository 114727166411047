import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    resources: {
      de: { translation: require('./de-DE.json') },
      en: { translation: require('./en-US.json') },
      es: { translation: require('./es-ES.json') },
      fr: { translation: require('./fr-FR.json') },
      id: { translation: require('./id-ID.json') },
      it: { translation: require('./it-IT.json') },
      ja: { translation: require('./ja-JP.json') },
      ko: { translation: require('./ko-KR.json') },
      pt: { translation: require('./pt-PT.json') },
      ru: { translation: require('./ru-RU.json') },
      th: { translation: require('./th-TH.json') },
      tr: { translation: require('./tr-TR.json') },
      vi: { translation: require('./vi-VI.json') },
      'zh-CN': { translation: require('./zh-CN.json') },
      'zh-HK': { translation: require('./zh-HK.json') },
      'zh-TW': { translation: require('./zh-TW.json') },
    },
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
