exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-company-scta-js": () => import("./../../../src/pages/company/scta.js" /* webpackChunkName: "component---src-pages-company-scta-js" */),
  "component---src-pages-delete-meal-js": () => import("./../../../src/pages/delete/meal.js" /* webpackChunkName: "component---src-pages-delete-meal-js" */),
  "component---src-pages-delete-wedo-js": () => import("./../../../src/pages/delete/wedo.js" /* webpackChunkName: "component---src-pages-delete-wedo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-bs-en-js": () => import("./../../../src/pages/policy/bs_en.js" /* webpackChunkName: "component---src-pages-policy-bs-en-js" */),
  "component---src-pages-policy-bs-js": () => import("./../../../src/pages/policy/bs.js" /* webpackChunkName: "component---src-pages-policy-bs-js" */),
  "component---src-pages-policy-en-js": () => import("./../../../src/pages/policy_en.js" /* webpackChunkName: "component---src-pages-policy-en-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-policy-meal-en-js": () => import("./../../../src/pages/policy/meal_en.js" /* webpackChunkName: "component---src-pages-policy-meal-en-js" */),
  "component---src-pages-policy-meal-js": () => import("./../../../src/pages/policy/meal.js" /* webpackChunkName: "component---src-pages-policy-meal-js" */),
  "component---src-pages-policy-pill-en-js": () => import("./../../../src/pages/policy/pill_en.js" /* webpackChunkName: "component---src-pages-policy-pill-en-js" */),
  "component---src-pages-policy-pill-js": () => import("./../../../src/pages/policy/pill.js" /* webpackChunkName: "component---src-pages-policy-pill-js" */),
  "component---src-pages-policy-plant-en-js": () => import("./../../../src/pages/policy/plant_en.js" /* webpackChunkName: "component---src-pages-policy-plant-en-js" */),
  "component---src-pages-policy-plant-js": () => import("./../../../src/pages/policy/plant.js" /* webpackChunkName: "component---src-pages-policy-plant-js" */),
  "component---src-pages-policy-urine-en-js": () => import("./../../../src/pages/policy/urine_en.js" /* webpackChunkName: "component---src-pages-policy-urine-en-js" */),
  "component---src-pages-policy-urine-js": () => import("./../../../src/pages/policy/urine.js" /* webpackChunkName: "component---src-pages-policy-urine-js" */),
  "component---src-pages-policy-wedo-en-js": () => import("./../../../src/pages/policy/wedo_en.js" /* webpackChunkName: "component---src-pages-policy-wedo-en-js" */),
  "component---src-pages-policy-wedo-js": () => import("./../../../src/pages/policy/wedo.js" /* webpackChunkName: "component---src-pages-policy-wedo-js" */),
  "component---src-pages-term-en-js": () => import("./../../../src/pages/term_en.js" /* webpackChunkName: "component---src-pages-term-en-js" */),
  "component---src-pages-term-js": () => import("./../../../src/pages/term.js" /* webpackChunkName: "component---src-pages-term-js" */)
}

